/**
 * @typedef {Object} StatusCodeHandlers
 * @prop {number} statusCode
 * @prop {StatusCodeHandler} handler
 */

/**
 * @param {*} apiError 
 * @param {StatusCodeHandlers[]} statusCodeHandlers 
 */
 export const apiErrorHandler = async (apiError, statusCodeHandlers = null) => {
    if (!apiError.response) { // if the api didn't respond, then the regular axios error response will not be set
      throw apiError
    }
    const { status, data } = apiError.response
    const statusCodeHandler = statusCodeHandlers?.find(s => s.statusCode === status)
    if (statusCodeHandler) {
      await statusCodeHandler.handler()
    }
    else {
      if (status === 400) {
        if (data.Message === 'Unable to save record. Record has been modified by another user.') {
          apiError.customErrorMessage = data.Message;
        }
        else if (data.ValidationErrors) {
          apiError.validationErrors = Object.keys(data.ValidationErrors).map((key) => {
            return {
              key,
              errors: data.ValidationErrors[key],
            }
          })
        }
      }
      else if (status === 404) {
        window.location.replace("/not-found")
      }
      else if (status === 401 || status === 403) {
        window.location.replace("/unauthorized")
      }
    }
    throw apiError
  }